import React from 'react';
import Layout from '../components/layout';
import Intro from '../components/intro';
import styled from '@emotion/styled';
import LinkedIn from '../assets/linkedIn.svg';

const TeamMembers = styled('section')`
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width : 650px) {
    flex-direction: column;
  }
`,
TeamMember = styled('div')`
  width: 30%;

  @media only screen and (max-width : 650px) {
    width: 100%;
    display: block;
  }

  .position {
    font-weight: 700;
  }

  .social img {
    width: 20px;
    height: 20px;
  }
`,
Team = () => (
  <Layout page="Our Team">
    <Intro>
      <h2>A team of skilled professionals at your service</h2>
      <p>We're a small enough business to be able to provide personal service - providing you with direct contact to
      the developers working on your project.</p>
    </Intro>

    <h3>How we work</h3>
    <p>We pride ourselves in keeping up with technology as well as being a flexible and fun place to work. At Talen, we like
    to work local, but operate global.</p>

    <p>With technology at our fingertips there’s no reason to be in one place. That’s why we operate a flexible
      workplace with staff in the office some days and at home other days. This allows everyone to get that work
      / life balance just right which is critical to quality and long term success.</p>

    <h3>Our Team</h3>

    <TeamMembers>
      <TeamMember>
        <h4>Luke Talbot</h4>
        <p className="position">CEO and Technical Architect</p>
        <p className="description">
          Luke has made his career in web application development over the past 20+ years and has worked with
          organisations of all shapes and sizes (BHP, CSL, Australian Government, Coles and CSC) to develop custom
          mobile and online solutions.
        </p>
        <p className="social">
          <a href="http://www.linkedin.com/in/luketalbot" target="_blank" rel="noopener noreferrer">
            <img src={LinkedIn} alt="LinkedIn" />
          </a>
        </p>
      </TeamMember>
      <TeamMember>
        <h4>Scott Ramsey</h4>
        <p className="position">Software Development Manager</p>
        <p className="description">
          Since 1999, Scott has worked with a range of clients from major corporates (IBM, BHP and ANZ) to non-profits
          and community groups. With extensive skills in Javascipt, Automation Testing and UX, Scott works closely with
          businesses to help achieve their online goals.
        </p>
        <p className="social">
          <a href="http://www.linkedin.com/in/scottramsey" target="_blank" rel="noopener noreferrer">
            <img src={LinkedIn} alt="LinkedIn" />
          </a>
        </p>
      </TeamMember>
      <TeamMember>
        <h4>Aidan Hildred</h4>
        <p className="position">Senior C# .Net Developer</p>
        <p className="description">
          Aidan is a senior software developer with experience working in the full software development life cycle since 2008. 
          He has worked in development and support of multi-tier, web-enabled applications, primarily in C# on the .NET platform 
          for clients of all sizes including CUB, Cadbury, Arnotts and Harvey Norman.
        </p>
        <p className="social">
          <a href="https://www.linkedin.com/in/aidan-hildred" target="_blank" rel="noopener noreferrer">
            <img src={LinkedIn} alt="LinkedIn" />
          </a>
        </p>
      </TeamMember>

    </TeamMembers>
  </Layout>
);

export default Team;
